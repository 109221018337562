import React, { useState } from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link, Input, Button, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import { useLoginState } from './userStateContext';
import CollapsibleDialog from '../components/CollapsibleDialog.js';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';
import article1 from '../resources/Articles/11-13 Editorial.pdf';
import article2 from '../resources/Articles/14-21 Kiran et al.pdf';
import article3 from '../resources/Articles/22-26 Vaishnav et al.pdf';
import article4 from '../resources/Articles/27-30 Agrawal et al.pdf';
import article5 from '../resources/Articles/31-41 Rajaram et al.pdf';
import article6 from '../resources/Articles/42-45 Sigh et al.pdf';
import contentArray from './resoucePages/AbstractContents.js';
export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);
	const [popupOpen, setPopupOpen] = useState(false);
	const [selectedContent, setSelectedContent] = useState(null);

	const handleOpenPopup = (content) => {
		setSelectedContent(content);
		setPopupOpen(true);
	};

	const handleClosePopup = () => {
		setPopupOpen(false);
		setSelectedContent(null);
	};

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"journal-management"} />
		<Helmet>
			<title>
				Journal
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"journal-management"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 80px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="90%">
				NJSOMA Volume 1| Issue 1| Jan 2024<br/>
            	<hr></hr>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				EDITORIAL
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Fetal Autopsy: Insight and Utility
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Sontakke YA, Chaudhari VA, Gowda M<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[0])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article1} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				ORIGINAL ARTICLES
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				C677T Genetic Polymorphisms in Clinicodevelopmental Spectrum of Neural Tube Defects
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Kiran K, Gladwin VR, Bhat BV, Kubera NS<br/>
				<a href="#2" onClick={()=>handleOpenPopup(contentArray[1])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article2} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Morphologic and Morphometric Study of Intercondylar Notch of Femur in Relation to Anterior Cruciate Ligament Injuries
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Vaishnav SP, Sharma D, Baweja S, Sharma V<br/>
				<a href="#2" onClick={()=>handleOpenPopup(contentArray[2])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article3} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				A Study of Anthropometric Assessment of Schedule Caste and Schedule Tribe Boys of Chhattisgarh
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Agrawal P, Chatterjee M, Agrawal PC<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[3])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article4} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				REVIEW ARTICLES
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				A Comprehensive Synthesis of the Current Knowledge regarding the Anatomy and Histology of the Triangular Fibrocartilage Complex of the Wrist Joint
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Rajaram N, Kumar VD<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[4])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article5} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				From Dissection Tables to Digital Screens: A Review of the Role of YouTube in Human Anatomy Learning
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Singh G, Mishra DN<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[5])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article6} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<CollapsibleDialog open={popupOpen} onClose={handleClosePopup} content={selectedContent} />
			<Text margin="0px 0px 20px 0px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				<hr></hr><br/>
			</Text>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 80px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="90%">
				NJSOMA Volume 1| Issue 2| July 2024<br/>
            	<hr></hr>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				EDITORIAL
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Time for Self-upliftment - AI Tools as a Teaching-Learning Resource
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Sontakke YA, Dinesh Kumar V<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[6])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article1} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				ORIGINAL ARTICLES
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Comparative Study of Pancreatic Head and Body Size between Diabetic and Non Diabetic Individual 35-65 Years by Ultrasonography
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Sisodiya H, Barjatya R, Solanki BS, Mathur R<br/>
				<a href="#2" onClick={()=>handleOpenPopup(contentArray[7])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article2} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Morphology and Morphometry of Glenoid Fossa of the Adult Human Scapula and its Clinical Application
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Narayana PSV, Devi KVNG, Kavitha T, Mayuri MVR<br/>
				<a href="#2" onClick={()=>handleOpenPopup(contentArray[8])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article3} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Self-Directed Learning as a Method for Teaching Gross Anatomy of Kidney among MBBS Students in a Medical College of Kolkata: An Interventional Comparative Study
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Biswas S, Srimani P, Nandy S<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[9])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article4} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans" max-width="90%">
				CASE REPORT
			</Text>
			<Text margin="0px 0px 20px 40px" font="normal 400 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Trifurcation of Left Coronary Artery with Absent Left Circumflex Artery and Superdominant Right Coronary Artery in the Heart
			</Text>
			<Text margin="0px 0px 60px 40px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				Nikilesh S, Ambiga R, Stephen PC, Verma S, Suma HY<br/>
				<a href="#" onClick={()=>handleOpenPopup(contentArray[10])}>Abstract</a>	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<a href={article5} target="_blank" rel="noopener noreferrer">PDF</a>
			</Text>
			<CollapsibleDialog open={popupOpen} onClose={handleClosePopup} content={selectedContent} />
			<Text margin="0px 0px 20px 0px" font="normal 200 20px/1.2 --fontFamily-sans" color="#505257" max-width="90%">
				<hr></hr><br/>
			</Text>
		</Section>
		{/* <Section id="PublicationsList">
			<Box
				padding="40px 40px 60px 40px"
				background="--color-orange"
				border-radius="24px"
				max-width="940px"
				width="100%"
				lg-max-width="480px"
				lg-padding="40px 40px 40px 40px"
			>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					id="Publication List"
				>
					<Link href="#" color="#000000">
						Document 1 - PlaceHolder
					</Link>
				</List>
			</Box>
		</Section> */}
		<Section />
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});